<template>
  <div class="goodData">
      <GoodDataCate :parentId="parentId" :childId="childId" />
      <GoodDataMain />
  </div>
</template>
<script>
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?5d6b1b39fc8f63765c4a2e30df4bd41d";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
</script>
<script>
import GoodDataCate from '@/components/layOut/GoodDataCate'
import GoodDataMain from '@/components/layOut/GoodDataMain'
export default {
  name:'GoodsData',
  components:{
    GoodDataCate,
    GoodDataMain
  },
 
  data(){
    return{
      parentId:'',
      childId:'',

    }
  },
  watch:{
     '$route':function(){
        let that = this;
        let query = that.$route.query;
        that.parentId = query.parentId+'';
        that.childId = query.childId+'';
      }
  },
  methods:{

  },
  created(){
      this.parentId = this.$route.query.parentId+'';
      this.childId = this.$route.query.childId+'';
  }

}
</script>

<style scoped>
.goodData{
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
</style>