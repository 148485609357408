<template>
  <div class="wrapper">
    <el-tabs type="border-card" v-model="activeName"  @tab-click="toSort">
      <el-tab-pane
        v-for="(item, index) in tabList"
        :key="index"
        :label="item.val"
        :name="index + ''"
      ></el-tab-pane>
      <div class="goodCon" v-loading="GoodLoading"  >
         <div v-if="goodList.length > 0 && !GoodLoading" >
        <el-row type="flex" :gutter="18" style="flex-wrap: wrap">
          <el-col :span="4.5" v-for="(item, index) in goodList" :key="index">
            <div class="colItem" @click="toDetails(item.goodsId)">
              <el-image
                :src="item.defaultImageUrl ? item.defaultImageUrl : item.imageUrl"
                class="goodImg" lazy
              ></el-image>
              <el-link :underline="false" class="goodDisc">
                <span v-if="item.isPutAway === 1" class="redSty">【已下架】</span>
              {{item.title}}</el-link>
              <!-- <p class="fontSm graySty">条码：33222255</p> -->
              <div class="priceCon displayRow">
                <div class="price">
                  <span class="nowPrice priceSty"
                    >￥{{item.minPrice ? Math.round(item.minPrice) : '--'}}</span
                  >
                  <s class="beforePrice graySty fontSm"
                    >￥{{item.maxPrice ? Math.round(item.maxPrice) :'--'}}</s
                  >
                </div>
                <div class="sale">{{ item.salesNum }}人已购买</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <p class="loadingSty" v-if="addPageFlag"><i class="el-icon-loading"></i> 加载中……</p>
        <!-- <el-pagination
         v-if="pageFlag"
          background
          layout="prev, pager, next"
          :page-size="50"
          :total="totalCount"
          :current-page="currentPage"
          @current-change="changePage"
          class="textCenter"
        >
        </el-pagination> -->
      </div>
         <NoData v-if="goodList.length == 0 && !GoodLoading"/>
      </div>
     
    </el-tabs>
  </div>
</template>

<script>
import Bus from "@/assets/js/bus.js";
import { getGoodsList } from "@/assets/js/api.js";
import NoData from'@/components/NoData'
export default {
  name:'GoodDataMain',
  components: {
    NoData
  },
  data() {
    return {
      // '综合','销量','评价','最新'
      tabList: [
        { key: "sortNum", val: "综合" },
        { key: "salesNum", val: "销量" },
        { key: "availableStockNum", val: "库存" },
        { key: "price", val: "价格" },
      ],
      activeName: '0',
      goodList: [],
      totalCount: 0,
      currentPage: 1,
      GoodLoading: true,
      srarchParams:{},
      Cateparam:{},
      Tabsparam:{},
      pageFlag:true,
      addPageFlag:false,
    };
  },
  computed: {
    
  },
   watch:{
     '$route':function(){
        let that = this;
        let query = that.$route.query;
        let searchVal = query.search;
        this.srarchParams = searchVal ? {search:searchVal} :{};
        this.Cateparam = {};
         this.currentPage = 1;
         this.goodList = [];
        that.getData();
      }
  },
  methods: {
    //tab切换 数据改变
    toSort() {
      this.GoodLoading = true;
      let index = +this.activeName;
     this.Tabsparam = {field:this.tabList[index].key,sort:'DESC'};
     this.currentPage = 1;
     this.goodList = [];
      this.getData();
    },


    getData() {
       this.pageFlag = true;
       let data = {
        pageNo: this.currentPage,
        pageSize:50
      };
      data = {...data,...this.Tabsparam,...this.Cateparam,...this.srarchParams};
     
      getGoodsList(data)
        .then((res) => {
          if (res.data.code === 200) {
            this.goodList.push(...res.data.result.pageList);
            this.addPageFlag = false;
            this.totalCount = res.data.result.totalCount;
          }
        })
        .finally(() => {
          this.GoodLoading = false;
        });
    },

    //获取商品详情
  toDetails(classifyId){
        let url= this.$router.resolve({
    name: "DetailsData",  
       query:{
        classifyId:classifyId
      }
  });
   window.open(url.href, "_blank");
  },
  handleScroll(e){
    var scrolltop = document.documentElement.scrollTop||document.body.scrollTop;
    /* 整个页面的正文高度 */
    var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
    /* 可见区域高度 */
    var clientHeight = document.documentElement.clientHeight||document.body.clientHeight;
      if(scrollHeight === parseInt(scrolltop+clientHeight) && !this.addPageFlag){
        this.addPageFlag = true;
        this.currentPage = this.currentPage + 1;
        this.getData();

		}
  }
  },
 
  mounted() {
    Bus.$on("changeCate", (data) => {
      this.GoodLoading = true;
      this.Cateparam = {goodsClassifyId:data};
      this.srarchParams = {};
      this.currentPage = 1;
      this.goodList = [];
      this.getData();
    });
    window.addEventListener('scroll', this.handleScroll, true)
  },
  created() {
    let imgSearchArr = localStorage.getItem('imgSearchArr');
    if(imgSearchArr){
      this.pageFlag = false;
      this.goodList = JSON.parse(imgSearchArr);
      this.GoodLoading = false;
    }else{
      this.pageFlag = true;
      let searchVal = this.$route.query.search;
    this.srarchParams = searchVal ? {search:searchVal} :{};
    this.currentPage = 1;
     this.goodList = [];
      this.getData();
    }

    
  },
};
</script>
<style scoped>
.wrapper {
  margin-top: 50px;
}
.colItem {
  width: 218px;
  margin-bottom: 30px;
  cursor: pointer;
}
.goodImg {
  width: 100%;
  height: 218px;
  padding-bottom: 10px;
}

.goodDisc {
  width: 218px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  display: block;
}
.priceCon {
  justify-content: space-between;
  padding-top: 10px;
}

.sale {
  color: #aaa;
  font-size: 13px;
}
.loadingSty{
  color:#1986fa;
  text-align: center;
}
</style>