<template>
  <div class="cate">
      <el-row class="area">
  <el-col :span="2" class="cateTitle">产品分类:</el-col>
  <el-col :span="22">
      <ul class="displayRow flexWrap">
              <!-- <li class="cateItem" :class="cateActived === -1 ? 'cateActive' : ''"><el-link  @click="allParent">全部</el-link></li> -->
              <li v-for="(item,index) in menuList" :key="index" class="cateItem" :class="cateActived === index ? 'cateActive' : ''" @click="parent(index,item.classifyId)">
                  <el-link >{{item.title}}</el-link></li>
          </ul>
  </el-col>
</el-row>

<el-row class="area" v-if="childrenArr.length > 0">
  <el-col :span="2" class="cateTitle">产品子类:</el-col>
  <el-col :span="22">
      <ul class="displayRow flexWrap">
              <!-- <li class="cateItem"><el-link>全部</el-link></li> -->
              <li v-for="(item,index) in childrenArr" :key="index" class="cateItem" :class="childActived === index ? 'cateActive' : ''" @click="child(index,item.classifyId)">
                  <el-link >{{item.title}}</el-link>
                  </li>
          </ul>
  </el-col>
</el-row>
  </div>
</template>

<script>
import Bus from '@/assets/js/bus.js'
import {getClassify} from '@/assets/js/api.js'
export default {
    props:{
        parentId:{
            type:String,
            default:''
        },
        childId:{
            type:String,
            default:''
        },

    },
 data(){
     return{
          menuList:[],
          childrenArr:[],
          activeNames:'',
          defaultBrand:[],
          allBrand:[],
          cateActived:-1,
          childActived:-1,
     }
 },
 watch:{
     changeOption:{
         deep:true,
         handler(val){
             if(val.MenuLen > 0 && (val.childId || val.parentId)) {
                 this.getChange();
             } 
         }
     }
 },
 computed:{
     changeOption(){
         const MenuLen = this.menuList.length;
         const childId = this.childId;
         const parentId = this.parentId;
        return {
            MenuLen,
            childId,
            parentId
        }
     }
 },
 methods:{
     getChange(){
         let index = this.menuList.findIndex(v => v.classifyId == this.parentId);
         if(index > -1){
            this.cateActived = index;
            this.childrenArr = this.menuList[index].childrenClassify;
         }
         if(this.childId){
              let childIndex = this.childrenArr.findIndex(v => v.classifyId == this.childId);
         if(childIndex > -1){
             this.child(childIndex,this.childId);
         }

         }
        
     },
       
     allParent(){
         this.childrenArr = [];
         this.cateActived = -1;
     },
     parent(index,classifyId){
         this.cateActived = index;
          this.childActived = 0;
         this.childrenArr = this.menuList[index].childrenClassify;
        Bus.$emit('changeCate',classifyId);
         //this.defaultBrand = this.menuList[index].brandList.slice(0,6);
        // this.allBrand = this.menuList[index].brandList.slice(6,this.menuList[0].brandList.length);
     },
     child(index,classifyId){
         this.childActived = index;
          Bus.$emit('changeCate',classifyId);
     },

    

     handleChange(){

     }
 },
 created(){
      //获取全部分类
     getClassify().then(res =>{
          if(res.data.code === 200){
                    this.menuList = res.data.result;
                }
     })
    // this.defaultBrand = this.menuList[0].brandList.slice(0,6);
    // this.allBrand = this.menuList[0].brandList.slice(6,this.menuList[0].brandList.length);
 }
}
</script>

<style scoped>
.cate{
    width:100%;
    padding:0 20px 20px 20px;
    box-sizing: border-box;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
}
.area{
    border-bottom: 1px dashed #eee;
    padding-top:10px;
}
.cateTitle{
    padding:5px;
}
.cateItem{
    padding:5px;
    margin-left: 10px;
    margin-bottom: 10px;
}
.cateActive{
    color:#0388DD;
    border:1px solid #0388DD;
}
.brandItem{
    width:114px;
    margin-left:30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>